import { format as formatFns, parse, parseISO } from 'date-fns';
import { de } from 'date-fns/locale';

/**
 * Formats a date.
 * @param {Date|string} date
 * @param {string} [format=dd.MM.yyyy]
 * @returns {string}
 */
export function formatDate(date, format = 'dd.MM.yyyy') {
  let d = null;
  if (typeof date === 'string') {
    d = parseISO(date);
  }
  if (!(d instanceof Date)) {
    return '';
  }

  return formatFns(d, format, {
    locale: de,
  });
}

/**
 * Get time im format HH:mm from a date object or a string.
 * @param {Date|string} date
 * @returns {string}
 */
export function getTimeString(date) {
  if (!date) {
    return '';
  }
  const d = date;
  if (typeof date === 'string') {
    return date.substr(11, 5);
  }

  return formatFns(d, 'HH:mm', {
    locale: de,
  });
}

/**
 * Formats a time string from format HH:mm to an ISO string.
 * @param {string} val
 * @returns {string}
 */
export function timeStringToISOString(val) {
  if (!val || val === '') {
    return null;
  }
  return `1970-01-01T${val}:00+0000`;
}
