<template>
  <div class="lds-ring" :style="{ '--spinner-size': size, '--spinner-color': color }">
    <div />
    <div />
    <div />
    <div />
  </div>
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    size: {
      type: String,
      default: '1.5rem',
    },
    color: {
      type: String,
      default: '#ffffff',
    },
  },
};
</script>

<style lang="scss" scoped>
.lds-ring {
  display: inline-block;
  position: relative;
  width: var(--spinner-size);
  height: var(--spinner-size);
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: var(--spinner-size);
  height: var(--spinner-size);
  margin: calc(var(--spinner-size) / 8);
  border-width: calc(var(--spinner-size) / 8);
  border-radius: 50%;
  border-style: solid;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--spinner-color) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
